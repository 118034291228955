import React, { useState, useEffect } from "react";
import logo1 from "../assets/images/nimmin/nimlogo1.png";
import logo2 from "../assets/images/nimmin/nimlogo2.png";
import logo3 from "../assets/images/nimmin/nimlogo3.png";
import logo5 from "../assets/images/nimmin/gyprlog1.png";
import logo6 from "../assets/images/nimmin/nimlogo6.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";

export const navItems = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about" },
  { name: "Innovation", path: "/inovation" },
  { name: "Gallery", path: "/people" },
  { name: "Contact", path: "/contact-us" },
];

export const productsDropdownItems = [
  {
    name: "BioAgriculture",
    path: "/Bio-gri",
    subItems: [
      { name: "VermiCompost", path: "/VermiCompost" },
      { name: "Liquid Poshan", path: "/Liquid-Poshan" },
      { name: "Nutrient Spray", path: "/Nutrient-Spray" },
      // { name: "MicroGen", path: "myco-gen" },
    ],
  },
  {
    name: "Bio Fibe",
    path: "/Bio-Fiber",
    subItems: [
      { name: "HousekeepingBrush", path: "/Housekeeping-Brush" },
      { name: "Textiles", path: "/Textiles" },
      { name: "Packaging", path: "/Packaging" },
      { name: "Paper", path: "/Paper" },
      { name: "Handicraft", path: "/Handicraft" },
      { name: "Vybrana", path: "/vybrana" },
      { name: "Raysha", path: "/raysha" },
    ],
  },
  {
    name: "BioConstruction",
    path: "/Bio-gri",
    subItems: [
      { name: "Wood", path: "/Wood" },
      { name: "Construction", path: "/Construction" },
      // { name: "MicroGen", path: "myco-gen" },
    ],
  },

  {
    name: "BioMedicinalProducts",
    path: "/Bio-gri",
    subItems: [
      { name: "Medicines", path: "/Medicines" },
      { name: "Minerals", path: "/Minerals" },
      { name: "Nutrients", path: "Nutrients" },
      // { name: "MicroGen", path: "myco-gen" },
    ],
  },

  {
    name: "Bio-Food Products",
    path: "/Bio-gri",
    subItems: [
      { name: "Flour", path: "/Flour" },
      { name: "Baby Food", path: "/Baby-Food" },
      { name: "Chips", path: "Chips" },
      { name: "Bakery Products ", path: "Bakery-Products " },

      { name: "Drinks", path: "Drinks" },
      { name: "Pickles", path: "Pickles" },
      { name: "Candies", path: "Candies" },

      // { name: "MicroGen", path: "myco-gen" },
    ],
  },
  {
    name: "BioColorants & Dyes",
    path: "/Bio-gri",
    subItems: [
      { name: "Mordant or Dye", path: "/Mordant-Dye" },
     
    ],
  },
];

const Dropdown = ({ items, isSidebarOpen }) => {
  const [showItems, setShowItems] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);
  const [clickTimeout, setClickTimeout] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 5); // Adjust scroll position as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleItemClick = (item) => {
    if (expandedItem === item.name) {
      setExpandedItem(null); // Close the sub-items if the item is already expanded
    } else {
      setExpandedItem(item.name); // Expand the sub-items of the clicked item
    }
  };

  const handleItemDoubleClick = (path) => {
    window.location.href = path; // Navigate to the item's path on double-click
  };

  const handleItemClickWithDoubleClick = (item) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleItemDoubleClick(item.path);
    } else {
      const timeout = setTimeout(() => {
        handleItemClick(item);
        setClickTimeout(null);
      }, 300); // Adjust the timeout duration as needed
      setClickTimeout(timeout);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowItems(!showItems)}
        className={`hover:text-gray-200  text-[16px] ${
          isSidebarOpen
            ? "text-gray-700"
            : isScrolled
            ? "text-black"
            : "text-white"
        }`}
      >
        Our Products
        <svg
          className="w-4 h-4 ml-1 inline-block fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path d="M7 10l5 5 5-5z" />
        </svg>
      </button>
      {showItems && (
        <div className="absolute left-0 mt-1 w-52 bg-white top-[20px] shadow-lg rounded-sm z-10">
          {items.map((item) => (
            <div key={item.name} className="relative">
              <button
                onClick={() => handleItemClickWithDoubleClick(item)}
                className="block px-4 py-1 text-gray-700 hover:bg-green-600 hover:rounded-[2px] hover:text-white w-full text-left"
              >
                {item.name}
              </button>
              {item.subItems && expandedItem === item.name && (
                <div className="bg-white shadow-lg rounded-sm z-10 lg:absolute lg:left-full lg:top-0 lg:w-52">
                  {item.subItems.map((subItem) => (
                    <a
                      key={subItem.name}
                      href={subItem.path}
                      className="block px-4 py-1 text-gray-700 hover:bg-green-600 hover:rounded-[2px] hover:text-white"
                    >
                      {subItem.name}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState(navItems[0].name);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 3);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const setActiveItemByPath = () => {
    const currentPath = window.location.pathname;
    const foundItem = navItems.find((item) => item.path === currentPath);
    if (foundItem) {
      setActiveNavItem(foundItem.name);
    } else {
      setActiveNavItem(navItems[0].name);
    }
  };

  useEffect(() => {
    setActiveItemByPath();
  }, []);

  const handleNavItemClick = (name, path) => {
    setActiveNavItem(name);
    setIsSidebarOpen(false);
    if (path.startsWith("#")) {
      const element = document.getElementById(path.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <div
        className={`fixed w-full xl:px-[100px] max-sm:px-4 z-20 ${
          isScrolled
            ? "bg-white h-20"
            : "bg-transparent max-sm:h-28 sm:h-24 xl:h-44 pt-5"
        } transition-all duration-700 ease-in-out`}
      >
        <div className="container mx-auto  flex justify-between items-center h-full">
          <a href="/">
            <div className="text-lg font-bold">
              {isScrolled ? (
                <div className="flex justify-center items-center gap-5">
                  <img
                    src={logo3}
                    alt="Logo"
                    className="max-sm:h-[40px] sm:h-4 xl:h-[50px]"
                  />
                  <img
                    src={logo2}
                    alt="Logo"
                    className="max-sm:h-5 sm:h-4 xl:h-7"
                  />
                </div>
              ) : (
                <div className="flex gap-5 justify-center items-center">
                  <img
                    src={logo6}
                    alt="Logo"
                    className="max-sm:h-[46px] sm:h-[35px] xl:h-[45px]"
                  />

                  <img
                    src={logo1}
                    alt="Logo"
                    className=" max-sm:h-6 sm:h-4 xl:h-7"
                  />
                </div>
              )}
            </div>
          </a>
          <div className="hidden md:flex space-x-8 sm:space-x-4 xl:space-x-8">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.path}
                className={`hover:text-gray-200 text-[16px] ${
                  isScrolled ? "text-black" : "text-white"
                } ${activeNavItem === item.name ? "text-yellow-500" : ""}`}
                onClick={() => handleNavItemClick(item.name, item.path)}
              >
                {item.name}
              </a>
            ))}
            <Dropdown
              items={productsDropdownItems}
              isSidebarOpen={isSidebarOpen}
            />
          </div>
          <a href="https://gyprc.com/">
            <div className="text-lg font-bold max-sm:hidden sm:hidden lg:block border-2 rounded-full p-2 bg-white">
              {isScrolled ? (
                <img src={logo5} alt="Logo" className="xl:h-12 lg:h-12 " />
              ) : (
                <img src={logo5} alt="Logo" className="lg:h-12 xl:h-12" />
              )}
            </div>
          </a>
          <div className="md:hidden">
            <button
              onClick={() => setIsSidebarOpen(true)}
              className="focus:outline-none"
            >
              <GiHamburgerMenu
                className={`text-3xl ${
                  isScrolled ? "text-black" : "text-white"
                }`}
              />
            </button>
          </div>
        </div>
        <hr />
      </div>

      {isSidebarOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-20 flex justify-end">
          <div className="bg-white w-64 h-full p-4">
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="text-gray-700 focus:outline-none mb-4"
            >
              <IoCloseSharp className="text-3xl" />
            </button>
            <div className="flex flex-col space-y-4">
              {navItems.map((item) => (
                <a
                  key={item.name}
                  href={item.path}
                  className={`text-gray-700 hover:text-blue-500 ${
                    activeNavItem === item.name ? "text-yellow-500" : ""
                  }`}
                  onClick={() => handleNavItemClick(item.name, item.path)}
                >
                  {item.name}
                </a>
              ))}
              <Dropdown
                items={productsDropdownItems}
                isSidebarOpen={isSidebarOpen}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
