import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Homepagee } from "./pages/home/HomePage";
import { About } from "./pages/about/About";
import { Inovation } from "./pages/Innovation";
import { People } from "./pages/People";
import { ContactUs } from "./pages/Contact";
import { VermiCompost } from "./pages/products/VermiComp";
import { BioAGri } from "./pages/products/BioAgri";
import { Fiber } from "./pages/products/Fiber";
import { Vybrana } from "./pages/products/Vybrana";
import { Raysha } from "./pages/products/Raysha";
import { LiquidPoshan } from "./pages/products/LiquidPoshan";
import { BrushFiber } from "./pages/products/BrushFiber";
import { Textiles } from "./pages/products/Textile";
import { Packaging } from "./pages/products/Packaging";
import { Handicraft } from "./pages/products/Handicraft";
import { Wood } from "./pages/products/Wood ";
import { Construction } from "./pages/products/Construction";
import { NutrientSpray } from "./pages/products/NutrientSpray ";
import { Medicines } from "./pages/products/Medicines";
import { Minerals } from "./pages/products/Minerals";
import { Nutrients } from "./pages/products/Nutrients ";
import { Flour } from "./pages/products/Flour";
import { BabyFood } from "./pages/products/BabyFood ";
import { BakeryProducts } from "./pages/products/BakeryProducts ";
import { Chips } from "./pages/products/Chips";
import { Drinks } from "./pages/products/Drinks";
import { Pickles } from "./pages/products/Pickles";
import { Candies } from "./pages/products/Candies";
import { MordantDye } from "./pages/products/MordantDye ";
import { Paper } from "./pages/products/Paper";

function MainR() {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  return (
    <Router>
      <div className="relative">
        <Routes>
          <Route path="/" element={<Homepagee />} />
          <Route path="/about" element={<About />} />
          <Route path="/inovation" element={<Inovation />} />
          <Route path="/people" element={<People />} />
          <Route path="/contact-us" element={<ContactUs />} />
          {/* products */}
          <Route path="/VermiCompost" element={<VermiCompost />} />
          <Route path="/Liquid-Poshan" element={<LiquidPoshan />} />
          S <Route path="/vybrana" element={<Vybrana />} />
          <Route path="/raysha" element={<Raysha />} />
          <Route path="/Bio-gri" element={<BioAGri />} />
          <Route path="/Bio-Fiber" element={<Fiber />} />
          <Route path="/Housekeeping-Brush" element={<BrushFiber />} />
          <Route path="/Textiles" element={<Textiles />} />
          <Route path="/Packaging" element={<Packaging />} />
          <Route path="/Paper" element={<Paper />} />
          <Route path="/Handicraft" element={<Handicraft />} />
          <Route path="/Wood" element={<Wood />} />
          <Route path="/Construction" element={<Construction />} />
          <Route path="/Nutrient-Spray" element={<NutrientSpray />} />
          <Route path="/Medicines" element={<Medicines />} />
          <Route path="/Minerals" element={<Minerals />} />
          <Route path="/Nutrients" element={<Nutrients />} />
          <Route path="/Flour" element={<Flour />} />
          <Route path="/Baby-Food" element={<BabyFood />} />
          <Route path="/Bakery-Products" element={<BakeryProducts />} />
          <Route path="/Chips" element={<Chips />} />
          <Route path="/Drinks" element={<Drinks />} />
          <Route path="/Pickles" element={<Pickles />} />
          <Route path="/Candies" element={<Candies />} />
          <Route path="/Mordant-Dye" element={<MordantDye />} />
        </Routes>
        <button
          className="fixed bottom-5 right-5 px-3 py-3 rounded-full text-3xl bg-blue-700 text-white shadow hover:bg-blue-900"
          onClick={isScrolled ? scrollToTop : scrollToBottom}
        >
          {isScrolled ? "↑" : "↓"}
        </button>
      </div>
    </Router>
  );
}

export default MainR;
